import { gql } from '@apollo/client'

export const GET_USER = gql`
  query getUser($auth0Id: String!) {
    getUser(auth0Id: $auth0Id) {
      id
      profilePicture
      bannerPicture
      displayName
      username
      activityBucks
      posts {
        id
      }
      followers {
        id
        username
        profilePicture
      }
      following {
        id
        username
        profilePicture
      }
      aboutMe
      bestCourse
      program
      verificationStatus
      institution {
        name
      }
    }
  }
`

export const SEARCH_INSTITUTION = gql`
  query SearchInstitutions($query: String!) {
    searchInstitutions(query: $query) {
      id
      name
      __typename
    }
  }
`