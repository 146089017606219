import React from 'react'

export default function RoundLoader() {
  return (
    <div className='w-full flex justify-center'>
      <div className="relative inline-block w-12 h-12 mt-12">
        <div className="absolute left-0 top-0 w-full h-full rounded-full border-2 border-white animate-loader"></div>
        <div className="absolute left-0 top-0 w-full h-full rounded-full border-2 border-white animate-loader delay-700"></div>
      </div>
    </div>
  )
}
