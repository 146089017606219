import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import RoundLoader from './RoundLoader';
import Layout from './Layout';

const ProtectedPages = ({ element } :any) => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect])

  if (isLoading || !isAuthenticated) {
    return (
    <Layout loading={false} message=''>
      <RoundLoader />
    </Layout>
  )}

  return <>{element}</>;

};

export default ProtectedPages;