import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
const { useLocation } = require('@gatsbyjs/reach-router')
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'

import { GET_USER } from '../graphql/queries'

import { GrHomeRounded } from "react-icons/gr"
import { AiOutlinePlus } from "react-icons/ai"
import { RiMessage2Line } from "react-icons/ri"
import { BiNotification } from "react-icons/bi"
import { FaCircleUser } from "react-icons/fa6"

export default function Navbar() {
  const location = useLocation()
  const [auth0Id, setAuth0Id] = useState<string | undefined>('')

  const { user } = useAuth0()

  if (typeof window === "undefined") {
    return null;
  }

  useEffect(() => {
    setAuth0Id(user?.sub)
  }, [user])

  const { error, data } = useQuery(GET_USER, {
    variables: { auth0Id },
    skip: !auth0Id,
  })

  useEffect(() => {
    if (error) { 
      console.log('Error fetching data: ', error);
    }
  }, [error]) 

  const navItems = [
    { link: '/', icon: <GrHomeRounded className='text-dimmed text-2xl'/> },
    { link: '/craft/', icon: <AiOutlinePlus className='text-dimmed text-4xl'/> },
    { link: '/chat/', icon: <RiMessage2Line className='text-dimmed text-3xl'/> },
    { link: '/notifications/', icon: <BiNotification className='text-dimmed text-3xl'/> },
    { link: '/profile/', icon: data?.getUser?.profilePicture || <FaCircleUser className='text-background text-3xl bg-dimmed rounded-full' /> }
  ]
  
  return (
    <ul className='flex items-center h-full justify-between gap-3'>
      {navItems.map(({link, icon}, index) => (
        <li key={index} className={`py-1 border-b ${location.pathname === `${link}` ? 'border-primary-orange' : ' border-transparent'}`}>
          <Link to={link}>
            {data?.getUser?.profilePicture && link === '/profile/' ? (
              <img src={data?.getUser?.profilePicture} alt='profile image' className='w-7 h-auto rounded-full' /> 
            ) : icon}
          </Link>
        </li>
      ))}
    </ul>
  )
}
