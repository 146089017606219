import "./src/styles/global.css"

import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { ApolloProvider } from "@apollo/client";
import client from "./src/hooks/apolloClient"

import ProtectedPages from './src/components/ProtectedPages'

export const wrapRootElement = ({ element }) => (
  <Auth0Provider
    domain={process.env.GATSBY_AUTH0_DOMAIN}
    clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
    redirectUri={process.env.GATSBY_AUTH0_CALLBACK_URL}
  >
    <ApolloProvider client={client}>
      {element}
    </ApolloProvider>
  </Auth0Provider>
)

export const wrapPageElement = ({ element, props }) => {
  const protectedPages = ['/account/verify-email/', '/profile/edit/', '/profile/', '/craft/']
  if (protectedPages.includes(props.location.pathname)) {
    return <ProtectedPages element={element} />
  }

  return element
}

export const onRouteUpdate = ({ location }) => {
  if (typeof window !== "undefined") {
    const { pathname, href } = location
    
    if (!pathname.startsWith('/login') && !pathname.startsWith('/callback') && !href.includes('auth.ju-materials.com') && !href.includes('accounts.google.com')) {
      localStorage.setItem('lastVisitedPage', pathname)
    }
  }
}
