import React, { useEffect, useState } from 'react'

export default function NotificationBar ({message} :any) {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (message) {
      setIsVisible(true);

      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [message]);

  if (!message) return null

  return (
    <div className={`fixed top-0 left-0 right-0 m-auto w-9/12 py-3 bg-secondary-background text-center rounded-b-md border-b border-r border-l border-primary-orange z-50 font-semibold transform transition-transform duration-300 ${isVisible ? 'translate-y-0' : '-translate-y-full'}`}>
      {message}
    </div>
  )
}
