import React, { useState, useEffect, useRef } from 'react'
const { useLocation } = require('@gatsbyjs/reach-router')

import Header from './Header'
import Navbar from './Navbar'
import Loading from './Loading'
import Sidebar from './Sidebar'
import NotificationBar from './NotificationBar'

interface LayoutProps {
  loading: boolean
  children: React.ReactNode
  message: string
}

export default function Layout({ loading, children, message }: LayoutProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const buttonRef = useRef<HTMLImageElement>(null);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  const location = useLocation()

  const handleTouchStart = (e: TouchEvent) => {
    setTouchStartX(e.touches[0].clientX)
  }

  const handleTouchMove = (e: TouchEvent) => {
    setTouchEndX(e.touches[0].clientX)
  }

  const handleTouchEnd = () => {
    if (touchEndX - touchStartX > 50) {
      setIsSidebarOpen(true)
    }

    if (touchStartX - touchEndX > 50) {
      setIsSidebarOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart)
    document.addEventListener('touchmove', handleTouchMove)
    document.addEventListener('touchend', handleTouchEnd)

    return () => {
      document.removeEventListener('touchstart', handleTouchStart)
      document.removeEventListener('touchmove', handleTouchMove)
      document.removeEventListener('touchend', handleTouchEnd)
    };
  }, [touchStartX, touchEndX]);
  
  return (
    <div>
      <header className={`w-full fixed top-0 z-10 ${location.pathname === '/profile/' ? 'bg-transparent' : 'bg-background border-b border-b-borders shadow-lg'}`}>
        <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} buttonRef={buttonRef} />
        {loading && <Loading />}
      </header>
      <main className={`pb-12 ${location.pathname === '/profile/' ? '' : 'mt-12'}`}>
        {children}
      </main>
      <nav className='fixed bottom-0 h-12 w-full bg-background border-t border-t-borders px-8'>
        <Navbar />
      </nav>
      <div
        id='sidebar'
        className={`fixed top-0 left-0 w-10/12 h-screen bg-background border-r border-r-borders z-20 transition-transform duration-500 ease-in-out transform ${ isSidebarOpen ? 'translate-x-0' : '-translate-x-full' }`}
      >
        <Sidebar />
      </div>

      {isSidebarOpen && (
        <div className='fixed inset-0 bg-black bg-opacity-50 z-10 transition-all duration-500' onClick={() => setIsSidebarOpen(false)}/>
      )} 

      <NotificationBar message={message}/> 
    </div>
  )
}
