import React from 'react';
import { Link } from 'gatsby';
const { useLocation } = require('@gatsbyjs/reach-router')
import { StaticImage } from 'gatsby-plugin-image';

import { RxHamburgerMenu } from "react-icons/rx"
import { CiSearch } from "react-icons/ci"

const Header = ({ setIsSidebarOpen, buttonRef }: any) => {
  const location = useLocation()

  const handleSidebarToggle = () => {
    setIsSidebarOpen((prevState: any) => !prevState);
  }

  return (
    <div className='flex items-center justify-between w-full h-12'>
      <button className='w-10 flex-1 ml-2' ref={buttonRef} onClick={handleSidebarToggle}>
        <RxHamburgerMenu className='text-light text-2xl' />
      </button>
      {location.pathname !== '/profile/' && (
        <Link to='/'>
          <img
            src='/images/logo.png'
            alt='Ju-materials Logo'
            className='w-10 h-auto'
          />
        </Link>
      )}
      <button className='flex-1 flex justify-end mr-2'>
        <CiSearch className='text-light text-2xl' />
      </button>
    </div>
  );
};

export default Header;
