import React from 'react';
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'gatsby';

import { FaRegUser } from "react-icons/fa6"
import { GoGear } from "react-icons/go"

const Sidebar = () => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0()

  const sideItems = [{
    heading: "Edit",
    content: [{
      name: "Profile",
      link: "/profile/edit/",
      icon: <FaRegUser className='text-xl text-borders' />
    }, {
      name: "Account",
      link: "/account/settings",
      icon: <GoGear className='text-2xl text-borders' />
    }]
  }]

  return (
    <div className="grid px-2">
      {sideItems.map((item, index) => (
        <ul key={index} className='border-t border-t-borders px-5 py-3 grid gap-y-3 semi'>
          <h4 className='font-semibold'>{item.heading}</h4>
          {item.content.map((contentItem, i) => (
            <li key={i} className='flex gap-3 items-center'>
              {contentItem.icon}
              <Link to={contentItem.link}>{contentItem.name}</Link>
            </li>
          ))}
        </ul>
      ))}
      {isAuthenticated ? (
        <button
          className="hover:cursor-pointer text-center text-primary-orange active:text-black transition-colors font-semibold"
          onClick={() => logout()}
        >
          Log out
        </button>
      ) : (
        <button
          className="hover:cursor-pointer text-center text-primary-orange active:text-black transition-colors font-semibold"
          onClick={() => loginWithRedirect()}
        >
          Log in
        </button>
      )}
    </div>
  );
};

export default Sidebar;
